import React from "react";

import { theme } from "../../style/theme";
import { URL } from "../../constants";

import TriangleBlur from "../../style/Header/triangleBlur";
import Header from "../../components/Header";
import { Text } from "../../style/Text";
import { CertificationFloor } from "../../components/CertificationFloor/CertificationFloor";
import { Container, Wrapper } from "../../style/Grid";
import Bubble from "../../components/Bubble";
import { Flex } from "../../style/Grid";
import TileButton from "../../components/TileButton";
import { Heading } from "../../style/Heading";

const EducationContent = ({
  headerPostImage,
  title,
  description,
  certificationTitle,
  certificationItems,
  bubbles,
}) => (
  <>
    <Header
      bgImage={headerPostImage}
      minHeight={["450px", null, "751px", null, null]}
      bottomComponents={<TriangleBlur />}
      containerJustifyContent="flex-end"
      shadowAfter={true}
    >
      <Heading
        as="h1"
        color="white.100"
        fontSize={[5, 6, 6, "48px", null]}
        fontWeight="300"
        lineHeight="1.3"
        maxWidth={["320px", "500px", "650px", null, null]}
        mb={["24px", null, null, null, null]}
      >
        {title}
      </Heading>
      <Text
        color="white.100"
        fontWeight="300"
        fontSize={[2, null, null, 2, null]}
        lineHeight="24px"
        maxWidth={["280px", "300px", "350px", "425px", null]}
        mb={["45px", null, "84px", 6, null]}
      >
        {description}
      </Text>
    </Header>

    <CertificationFloor
      title={certificationTitle}
      items={certificationItems || []}
    />

    {bubbles && bubbles.length > 0 && (
      <Wrapper
        as="section"
        bg={theme.colors.EYOffBlack.default}
        overflow="hidden"
      >
        <Container
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
          my={["48px", "64px", "96px", "128px", null]}
        >
          {bubbles.map((e, index) => (
            <Bubble
              left={!(index % 2)}
              light={false}
              wide={true}
              description={e.description}
              name={e.name}
              position={e.position}
              department={e.department}
              image={e.image}
              button={
                e?.button?.link && e?.button?.text
                  ? {
                      href: e.button.link,
                      label: e.button.text,
                    }
                  : null
              }
              heading={e.title}
            />
          ))}
        </Container>
      </Wrapper>
    )}

    <section>
      <Flex>
        <TileButton
          width="100%"
          tileColor="light"
          tileOrientation="right"
          to={URL.jobs}
        >
          <Flex flexDirection="column" alignItems="flex-end">
            <Text
              fontSize={["18px", null, "5", null, null]}
              color="EYOffBlack.tile"
            >
              Chci se přidat k EY
            </Text>
            <Text
              fontSize={[1, null, 2, null, null]}
              fontWeight="300"
              color="EYOffBlack.tile"
              opacity=".7"
              mt={["8px", null, "24px", null, null]}
            >
              Prohlédni si aktuální volné pozice
            </Text>
          </Flex>
        </TileButton>
      </Flex>
    </section>
  </>
);

export { EducationContent };
